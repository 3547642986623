import React, {memo, useEffect} from 'react';
import {navigate} from 'gatsby';

const Pricing = () => {
  useEffect(() => {
    navigate('/pricing/');
  }, []);

  return <></>;
};

export default memo(Pricing);
